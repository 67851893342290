<body>
  <div class="container">
    <div *ngIf="idInput" class="row respondendid-section">
      <div id="existform">
        <div>
          <label class="responseid-text">Enter Site Audit Form Response ID </label>
        </div>
        <div>
          <input style="text-transform:uppercase" type="text" class="form-control" [(ngModel)]="respondentId" required>
        </div>
        <div>
          <button class="blue-button" (click)="addButton()">Go</button><br>
          <a (click)="accessverfy()" class="newlink">Click here to create new project</a><br><!--routerLink="REAT"-->
          <div style="max-height: 800px; overflow: auto; margin-top: 20px;">
            <table style="width: 800px;border: 0px;border-collapse: separate;border-spacing: 10px;">
              <tr class="versionbutton" routerLink="module-component" role="button"
                (click)="next(dbelement.RespondentID, dbelement.Version)" *ngFor="let dbelement of dbdict">
                <td>{{dbelement.RespondentID}} | {{dbelement.Version}} | Project - {{dbelement.ProjectName}} | Last
                  modified {{!!dbelement.LastUpdate ? dbelement.LastUpdate : dbelement.RecordCreated}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div *ngIf="formrespask" id="createbut">
        <br><br>
        <button class="blue-button" (click)="new_form()">Create New</button>
      </div>
    </div>
    <div *ngIf="loginput" class="row respondendid-section">
      <div style="width: 100%;">
        <label>User-id: &nbsp;</label>
        <input type="text" [(ngModel)]="credid" class="log-input">
        <br>
      </div>
      <div style="width: 100%;">
        <label>Password: &nbsp;</label>
        <input type="password" [(ngModel)]="credpass" class="log-input"><br>
      </div>
      <button class="blue-button" (click)="credentialverify()">login</button>
    </div>
  </div>

</body>
