import { Component, OnInit } from '@angular/core';
import { PlatformLocation } from '@angular/common'
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  subscription: Subscription;
  isLoggedIn = false;

  title = 'Armstrong REAT';

  constructor(private router: Router, private location: PlatformLocation) {
    location.onPopState(() => {
      this.pagerefresh()
    });
  }

  ngOnInit(): void {
    this.checkIsLoggedIn();
    setInterval(() => {
      this.checkIsLoggedIn();
    }, 1000);
  }

  checkIsLoggedIn() {
    this.isLoggedIn = sessionStorage.getItem('username') && !!sessionStorage.getItem('accessToken');
  }

  pagerefresh() {
    this.router.navigateByUrl("/")
  }

  logout() {
    sessionStorage.clear();
    this.isLoggedIn = false;
    this.router.navigateByUrl("/login");
  }
}
