import { Component, OnInit } from '@angular/core';
import { LocaldataserviceService } from '../../localdataservice.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-viewreport',
  templateUrl: './viewreport.component.html',
  styleUrls: ['./viewreport.component.css']
})
export class ViewreportComponent implements OnInit {

  urlSafe: SafeResourceUrl;

  constructor(
    public sanitizer: DomSanitizer,
    private _flaskservice: LocaldataserviceService
  ) { }

  ngOnInit(): void {
    const urlToReport= this._flaskservice.eahtmllink();
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlToReport);
  }

}
