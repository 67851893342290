<html>

    <a id="userguide" align="right" style="margin-left:990px; margin-bottom:-100px; color: red;" href="../assets/REAT repor printing user guide 09-01-23.pdf">USER-GUIDE</a>

<body>
<table id="module_tab">
    <tr>
        <td >
            <a><button (click)="redirect_Epic()" >EPIC – Pump analysis</button></a>
        </td>
    </tr>
    <tr style="height: 20px;"></tr>
    <tr>
        <td>
            <!-- <button routerLink="/ea-module">Energy Analyzer – Chilled water plant analysis</button> -->
            <button (click)="callEA()">Energy Analyzer – Chilled water plant analysis</button>
        </td>
    </tr>
    <tr style="height: 20px;"></tr>
    <tr>
        <td>
            <button (click)="callroute()">Roadmap report – 3 step roadmap to maximizing chilled water plant efficiency</button>
        </td>
    </tr>
</table>

<button style="display: inline-block;" class="blue-button5" (click)="back()" >Back</button>

<!-- <object data="../assets/REAT repor printing user guide 09-01-23.pdf" type="application/pdf" width="100%" height="100%">
    <a href="../assets/REAT repor printing user guide 09-01-23.pdf">test.pdf</a>
    </object> -->

</body>
</html>
