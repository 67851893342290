import { Injectable, Input } from '@angular/core';
import {HttpClient,HTTP_INTERCEPTORS,HttpErrorResponse,HttpResponse} from  "@angular/common/http"
import { Observable, throwError } from 'rxjs';
import { of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { ArrayType } from '@angular/compiler';
import { map, take } from 'rxjs/operators';
import  weathercitylist from './weathercity.json';
import {AppComponent} from './app.component'
import { Router } from '@angular/router'
import {LoginComponent} from './login/login.component'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LocaldataserviceService {
  public tempData: object;
  public editInput
  public formrespask
  public respondentId
  private _url1: string ;
  private postdata1: object = [];
  private statuspoint: object = [];
  private generateurl: string;
  private updateurl: string
  private noofversion: number = 0;
  private postdata2: boolean;
  public selectedresponse: string;
  public selectedindex: string="1";
  private newform: boolean = true;
  private createver: string;
  private addmodify: boolean;
  public filestatus: any;
  private generated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  generated$:  Observable<boolean> = this.generated.asObservable();

  private idInput: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  idInput$:  Observable<boolean> = this.idInput.asObservable();

  private generateresponse={"filePath": "Not here",
                            "status": "Trying"}
  // private hero = {"BaseCaseCondenserDesignDeltaT": "17",
  // "InputBaseCaseCondenserDesignDeltaTUnit": "Fahrenheit",
  // "BaseCaseEvaporatorDesignDeltaT": "14",
  // "InputBaseCaseEvaporatorDesignDeltaTUnit": "Fahrenheit",
  // "PrimaryHead": "70.0",
  // "InputPrimaryHeadUnit": "ft",
  // "PrimaryMaxFlow": "1200.0",
  // "InputPrimaryMaxFlowUnit": "US gpm",
  // "PowerCost": "0.1",
  // "Currency": "USD",
  // "BaseCaseNamePlateCapacity": "500.0",
  // "InputBaseCaseNamePlateCapacityUnit": "Ton",
  // "CityName": "Toronto",
  // "DaysPerWeek": "7",
  // "DesignDayCoolingLoad": "900.0",
  // "InputDesignDayCoolingLoadUnit": "Ton",
  // "DistributionCircuitConfiguration":"ConstantPrimary",
  // "HoursPerDay": "24",
  // "MonthPerYear": "6",
  // "ProjectName": "Armstrong",
  // "PrimaryPumpNumber": "2",
  // "SecondaryHead": "85.0",
  // "InputSecondaryHeadUnit": "ft",
  // "SecondaryMaxFlow": "600",
  // "SecondaryNumber": "2",
  // "BaseCaseChillerMinEcwt": "55.0",
  // "InputBaseCaseChillerMinEcwtUnit": "Fahrenheit",
  // "BaseCaseChillerModel": "TypicalConstantSpeedCentrifugalChiller",
  // "BaseCaseDesigndayChwstSp": "42",
  // "InputBaseCaseDesigndayChwstSpUnit": "Fahrenheit",
  // "BaseCaseNumberOfChillers": "2",
  // "BaseCaseNumberOfDutyChillers": "2",
  // "CWHead": "100",
  // "CWMaxFlow": "1500",
  // "CWNumber": "2",
  // "HorsePowerPerFan": "25",
  // "NumberOfCoolingTowers": "2",
  // "TotalNumberOfFans": "4",
  // "PrimaryYearInstalled": "2020",
  // "PrimaryVariableSpeed": "No",
  // "PrimaryPumpSystemConfiguration": "Dedicated",
  // "PrimaryPumpControlMethod": "Sensorless",
  // "PrimaryMotorPowerOnNameplate": "12",
  // "PrimaryInputMotorPowerOnNameplateUnit": "HP",
  // "SecondaryYearInstalled": "2020",
  // "SecondaryVariableSpeed": "No",
  // "SecondaryPumpSystemConfiguration": "Headered",
  // "SecondaryPumpControlMethod": "Sensorless",
  // "SecondaryMotorPowerOnNameplate": "12",
  // "SecondaryInputMotorPowerOnNameplateUnit": "HP",
  // "CondenserYearInstalled": "2020",
  // "CondenserVariableSpeed": "No",
  // "CondenserPumpSystemConfiguration": "Headered",
  // "CondenserPumpControlMethod": "Sensorless",
  // "CondenserMotorPowerOnNameplate": "23",
  // "CondenserInputMotorPowerOnNameplateUnit": "HP",
  // "SalesPerson": "Sales User",
  // "State": "Ontario",
  // "Country": "Canada",
  // "BuildingLoadProfile": "Data Center",
  // "TowerResetTemperature": "90",
  // "InputTowerResetTemperatureUnit": "Fahrenheit",
  // "RespondentID": "54321",
  // "UpdateEmail": null,
  // "Version": 1,
  // "RecordCreated": "Tue, 05 May 2020 05:37:26 GMT",
  // "PhoneNo": "123898765",
  // "Email": "rgossain@armstrongfluidtechnology.com",
  // "ReportGeneratedOn": null,
  // "LastUpdate":  "Tue, 05 May 2020 05:37:26 GMT",
  // "FanMotorType": "Constant Speed",
  // "SecondaryMaxFlowInputUnit": "US gpm",
  // "CWHeadInputUnit": "ft",
  // "CWMaxFlowInputUnit": "US gpm",
  // "SystemYearInstalled": "2008",
  // "ReportPath": null,
  // "Weathercity": "Bangalore",
  // "observedCHWDeltaT": "10",
  // "observedCHWDeltaTUnit": "Fahrenheit",
  // "observedCWDeltaT": "15",
  // "observedCWDeltaTUnit": "Fahrenheit",
  // "useObsValue": "False",
  // "ObservedBaseCasekWh": "0",
  // "ObservedPlantTonHours": "0",
  // "ObservedBaseCaseEfficiency": "0",
  // "SolutionOption":"",
  // "OptimizedCaseNamePlateCapacity": "799.862485",
  // "OptimizedCaseChillerMinEcwt": "64.4",
  // "OptimizedCaseChillerModel": "TypicalConstantSpeedCentrifugalChiller",
  // "OptimizedCaseCondenserDesignDeltaT": "10.8",
  // "OptimizedCaseDesigndayChwstSp": "44",
  // "OptimizedCaseEvaporatorDesignDeltaT": "12.6",
  // "OptimizedCaseNumberOfChillers": "2",
  // "OptimizedCaseNumberOfDutyChillers": "2",
  // "ReportType": "",
  // "InputOptimizedCaseNamePlateCapacityUnit": "Ton",
  // "InputOptimizedCaseChillerMinEcwtUnit": "Fahrenheit",
  // "InputOptimizedCaseCondenserDesignDeltaTUnit": "Fahrenheit",
  // "InputOptimizedCaseDesigndayChwstSpUnit": "Fahrenheit",
  // "InputOptimizedCaseEvaporatorDesignDeltaTUnit": "Fahrenheit",
  // "ReportPath_EA": null

  // }

 private UPDATE_DATA ={
  "BaseCaseCondenserDesignDeltaT":"17",
  "InputBaseCaseCondenserDesignDeltaTUnit":"Fahrenheit",
   "BaseCaseEvaporatorDesignDeltaT":"14",
   "InputBaseCaseEvaporatorDesignDeltaTUnit":"Fahrenheit",
   "PrimaryHead":"70.0",
   "InputPrimaryHeadUnit":"ft",
   "PrimaryMaxFlow":"1200.0",
   "InputPrimaryMaxFlowUnit":"US gpm",
   "PowerCost":"0.1",
   "Currency":"USD",
   "BaseCaseNamePlateCapacity":"500.0",
   "InputBaseCaseNamePlateCapacityUnit":"Ton",
   "CityName":"Toronto",
   "DaysPerWeek":"7",
   "DesignDayCoolingLoad":"900.0",
   "InputDesignDayCoolingLoadUnit":"Ton",
 "DistributionCircuitConfiguration":"ConstantPrimary",
   "HoursPerDay":"24",
   "MonthPerYear":"6",
   "ProjectName":"Armstrong",
   "PrimaryPumpNumber":"2",
   "SecondaryHead":"85.0",
   "InputSecondaryHeadUnit":"ft",
   "SecondaryMaxFlow":"600",
   "SecondaryNumber":"2",
   "BaseCaseChillerMinEcwt":"55.0",
   "InputBaseCaseChillerMinEcwtUnit":"Fahrenheit",
   "BaseCaseChillerModel":"TypicalConstantSpeedCentrifugalChiller",
   "BaseCaseDesigndayChwstSp":"42",
   "InputBaseCaseDesigndayChwstSpUnit":"Fahrenheit",
   "BaseCaseNumberOfChillers":"2",
   "BaseCaseNumberOfDutyChillers":"2",
   "CWHead":"100",
   "CWMaxFlow":"1500",
   "CWNumber":"2",
   "HorsePowerPerFan":"25",
   "NumberOfCoolingTowers":"2",
   "TotalNumberOfFans":"4",
   "PrimaryYearInstalled":"2020",
   "PrimaryVariableSpeed":"No",
   "PrimaryPumpSystemConfiguration":"Dedicated",
   "PrimaryPumpControlMethod":"Sensorless",
   "PrimaryMotorPowerOnNameplate":"12",
   "PrimaryInputMotorPowerOnNameplateUnit":"HP",
   "SecondaryYearInstalled":"2020",
   "SecondaryVariableSpeed":"No",
   "SecondaryPumpSystemConfiguration":"Headered",
   "SecondaryPumpControlMethod":"Sensorless",
   "SecondaryMotorPowerOnNameplate":"12",
   "SecondaryInputMotorPowerOnNameplateUnit":"HP",
   "CondenserYearInstalled":"2020",
   "CondenserVariableSpeed":"No",
   "CondenserPumpSystemConfiguration":"Headered",
   "CondenserPumpControlMethod":"Sensorless",
   "CondenserMotorPowerOnNameplate":"23",
   "CondenserInputMotorPowerOnNameplateUnit":"HP",
   "SalesPerson":"Sales User",
   "State":"Ontario",
   "Country":"Canada",
   "BuildingLoadProfile":"Data Center",
   "TowerResetTemperature":"90",
   "InputTowerResetTemperatureUnit":"Fahrenheit",
   "RespondentID":"REAT00019",
   "UpdateEmail":null,
   "Version":1,
   "PhoneNo":"123898765",
   "Email":"rgossain@armstrongfluidtechnology.com",
   "ReportGeneratedOn":null,
   "FanMotorType":"Constant Speed",
   "SecondaryMaxFlowInputUnit":"US gpm",
   "CWHeadInputUnit":"ft",
   "CWMaxFlowInputUnit":"US gpm",
   "SystemYearInstalled":"2010",
   "ReportPath":null,
   "Weathercity": "Bangalore",
   "observedCHWDeltaT": "10",
   "observedCHWDeltaTUnit": "Fahrenheit",
   "observedCWDeltaT": "15",
   "observedCWDeltaTUnit": "Fahrenheit",
   "useObsValue": "False",
   "ObservedBaseCasekWh": "0",
   "ObservedPlantTonHours": "0",
   "ObservedBaseCaseEfficiency": "0",
   "SolutionOption":"",
   "OptimizedCaseNamePlateCapacity": "799.862485",
   "OptimizedCaseChillerMinEcwt": "64.4",
   "OptimizedCaseChillerModel": "TypicalConstantSpeedCentrifugalChiller",
   "OptimizedCaseCondenserDesignDeltaT": "10.8",
   "OptimizedCaseDesigndayChwstSp": "44",
   "OptimizedCaseEvaporatorDesignDeltaT": "12.6",
   "OptimizedCaseNumberOfChillers": "2",
   "OptimizedCaseNumberOfDutyChillers": "2",
   "ReportType": "",
   "InputOptimizedCaseNamePlateCapacityUnit": "Ton",
   "InputOptimizedCaseChillerMinEcwtUnit": "Fahrenheit",
   "InputOptimizedCaseCondenserDesignDeltaTUnit": "Fahrenheit",
   "InputOptimizedCaseDesigndayChwstSpUnit": "Fahrenheit",
   "InputOptimizedCaseEvaporatorDesignDeltaTUnit": "Fahrenheit",
   "ReportPath_EA": null}

 private NEW_DATA ={
  "BaseCaseCondenserDesignDeltaT":"17",
  "InputBaseCaseCondenserDesignDeltaTUnit":"Fahrenheit",
   "BaseCaseEvaporatorDesignDeltaT":"14",
   "InputBaseCaseEvaporatorDesignDeltaTUnit":"Fahrenheit",
   "PrimaryHead":"70.0",
   "InputPrimaryHeadUnit":"ft",
   "PrimaryMaxFlow":"1200.0",
   "InputPrimaryMaxFlowUnit":"US gpm",
   "PowerCost":"0.1",
   "Currency":"USD",
   "BaseCaseNamePlateCapacity":"500.0",
   "InputBaseCaseNamePlateCapacityUnit":"Ton",
   "CityName":"Toronto",
   "DaysPerWeek":"7",
   "DesignDayCoolingLoad":"900.0",
   "InputDesignDayCoolingLoadUnit":"Ton",
   "DistributionCircuitConfiguration":"ConstantPrimary",
   "HoursPerDay":"24",
   "MonthPerYear":"6",
   "ProjectName":"Armstrong",
   "PrimaryPumpNumber":"2",
   "SecondaryHead":"85.0",
   "InputSecondaryHeadUnit":"ft",
   "SecondaryMaxFlow":"600",
   "SecondaryNumber":"2",
   "BaseCaseChillerMinEcwt":"55.0",
   "InputBaseCaseChillerMinEcwtUnit":"Fahrenheit",
   "BaseCaseChillerModel":"TypicalConstantSpeedCentrifugalChiller",
   "BaseCaseDesigndayChwstSp":"42",
   "InputBaseCaseDesigndayChwstSpUnit":"Fahrenheit",
   "BaseCaseNumberOfChillers":"2",
   "BaseCaseNumberOfDutyChillers":"2",
   "CWHead":"100",
   "CWMaxFlow":"1500",
   "CWNumber":"2",
   "HorsePowerPerFan":"25",
   "NumberOfCoolingTowers":"2",
   "TotalNumberOfFans":"4",
   "PrimaryYearInstalled":"2020",
   "PrimaryVariableSpeed":"No",
   "PrimaryPumpSystemConfiguration":"Dedicated",
   "PrimaryPumpControlMethod":"Sensorless",
   "PrimaryMotorPowerOnNameplate":"12",
   "PrimaryInputMotorPowerOnNameplateUnit":"HP",
   "SecondaryYearInstalled":"2020",
   "SecondaryVariableSpeed":"No",
   "SecondaryPumpSystemConfiguration":"Headered",
   "SecondaryPumpControlMethod":"Sensorless",
   "SecondaryMotorPowerOnNameplate":"12",
   "SecondaryInputMotorPowerOnNameplateUnit":"HP",
   "CondenserYearInstalled":"2020",
   "CondenserVariableSpeed":"No",
   "CondenserPumpSystemConfiguration":"Headered",
   "CondenserPumpControlMethod":"Sensorless",
   "CondenserMotorPowerOnNameplate":"23",
   "CondenserInputMotorPowerOnNameplateUnit":"HP",
   "SalesPerson":"Sales User",
   "State":"Ontario",
   "Country":"Canada",
   "BuildingLoadProfile":"Data Center",
   "TowerResetTemperature":"90",
   "InputTowerResetTemperatureUnit":"Fahrenheit",
   "UpdateEmail":null,
   "PhoneNo":"123898765",
   "Email":"rgossain@armstrongfluidtechnology.com",
   "ReportGeneratedOn":null,
   "FanMotorType":"Constant Speed",
   "SecondaryMaxFlowInputUnit":"US gpm",
   "CWHeadInputUnit":"ft",
   "CWMaxFlowInputUnit":"US gpm",
   "SystemYearInstalled":"2010",
   "ReportPath":null,
   "Weathercity": "Bangalore",
   "observedCHWDeltaT": "10",
   "observedCHWDeltaTUnit": "Fahrenheit",
   "observedCWDeltaT": "15",
   "observedCWDeltaTUnit": "Fahrenheit",
   "useObsValue": "False",
   "ObservedBaseCasekWh": "0",
   "ObservedPlantTonHours": "0",
   "ObservedBaseCaseEfficiency": "0",
   "SolutionOption":"",
   "OptimizedCaseNamePlateCapacity": "799.862485",
   "OptimizedCaseChillerMinEcwt": "64.4",
   "OptimizedCaseChillerModel": "TypicalConstantSpeedCentrifugalChiller",
   "OptimizedCaseCondenserDesignDeltaT": "10.8",
   "OptimizedCaseDesigndayChwstSp": "44",
   "OptimizedCaseEvaporatorDesignDeltaT": "12.6",
   "OptimizedCaseNumberOfChillers": "2",
   "OptimizedCaseNumberOfDutyChillers": "2",
   "ReportType": "",
   "InputOptimizedCaseNamePlateCapacityUnit": "Ton",
   "InputOptimizedCaseChillerMinEcwtUnit": "Fahrenheit",
   "InputOptimizedCaseCondenserDesignDeltaTUnit": "Fahrenheit",
   "InputOptimizedCaseDesigndayChwstSpUnit": "Fahrenheit",
   "InputOptimizedCaseEvaporatorDesignDeltaTUnit": "Fahrenheit",
   "ReportPath_EA": null}


 private GENERATE_DATA ={}

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': "application/json",
      'Access-Control-Allow-Origin':'*'
            //'ksinternalrespondentid': '54321'
    })
  };
  //handleError()
  constructor(private httpclient: HttpClient,private router: Router,) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` +`body was: ${error.error}`);
   }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    this.generated.next(true);
  };

enablefirstpage(){
  this.idInput.next(false)
}

authenticate(user_id,pass){
  const redirectUrl = environment.baseUrl + "/login";
  return this.httpclient.post("https://qa.pumpmanager.io/api/user/ad/login",
   // {"redirectUrl": "https://reatqa.armstrongfluidtechnology.com/login",
 {"redirectUrl": redirectUrl,
  "applicationType": "AZURE.REAT.LOCAL"
},{headers: {"Content-Type": "application/json"}})
}
// autherize(user_id,pass_key){
//   return this.httpclient.post("https://qa.pumpmanager.io/api/user/ad/login",
//   {
//     "redirectUrl": "http://localhost:4201",
//     "applicationType": "AZURE.REAT.LOCAL"
//   }
//   ,{headers: {"Content-Type": "application/json"}})
// }

ADAuthorization(entity: any, parameter: any, url: any): Observable<any> {
  var headers_object = new HttpHeaders();
  headers_object.append("Access-Control-Allow-Headers", "Content-Type");
  headers_object.append("Access-Control-Allow-Origin","*");
  headers_object.append("Accept", "application/json");
  headers_object.append('Content-Type', 'application/json');

  const httpOptions = {
    headers: headers_object
  };
  return this.httpclient.get<any>(`${"https://qa.pumpmanager.io/api/user/ad/authorize"}` + '?code=' + entity + '&applicationType=' + parameter + '&redirectUrl=' + url, httpOptions);
}

// UserLog(user_id:any){
//   "email"= user_id,

//   var headers_object = new HttpHeaders();
//   headers_object.append("Access-Control-Allow-Headers", "Content-Type");
//   headers_object.append("Access-Control-Allow-Origin","*");
//   headers_object.append("Accept", "application/json");
//   headers_object.append('Content-Type', 'application/json');

//   const httpOptions = {
//     headers: headers_object
//   }



  UserLog(user_id:any)  {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let payload = {email: user_id, deviceId:"", deviceType: "chrome", platform: "Web browser", applicationType:"REAT"};
    this.httpclient.post('https://qa.pumpmanager.io/api/user/lastLoggedIn', payload, { headers: headers })
      .subscribe((response) => {
        console.log(response);
      });
  }


connecting(){
  this.httpclient.get(environment.backendUrl + "/api/laodapp",{headers: {"Content-Type": "application/json"}})
  .subscribe(response => {
    console.log(response)

     })
}


  async getRespondentDetails(respondentId) {
    let url = String(environment.backendUrl + "/api/v1/resources/response/" + respondentId)
    const data = await this.httpclient.get<any[]>(url, { headers: { "Content-Type": "application/json" } }).toPromise();

    this.postdata1 = data
    return data;
  }

  async getRespondentDetailsByEmail(email: string) {
    const url = `${environment.backendUrl}/api/v1/resources/response/all?email=${email}`;
    const data = await this.httpclient.get<any[]>(url, { headers: { "Content-Type": "application/json" } }).toPromise();

    this.postdata1 = data
    return data;
  }

demodata(){
  console.log(this.NEW_DATA["BuildingLoadProfile"])
  return this.NEW_DATA
}

//:Observable<HttpResponse<string>>
updatedata(datarecord){
  //datarecord=JSON.stringify(datarecord)
  let recorddata= datarecord
  delete recorddata["LastUpdate"]
  delete recorddata["RecordCreated"]
  delete recorddata["ReportGeneratedOn"]

  console.log(recorddata,"Checking")
  console.log("working")
  console.log("Hi")
  setTimeout(() => {
    this.generated.next(true);
}, 10000);
  try {
    this.updateurl = String(environment.backendUrl + "/api/v1/resources/response/update/"+this.selectedresponse+"/"+this.selectedindex)
   console.log(this.updateurl)
   this.httpclient.patch(this.updateurl, datarecord,this.httpOptions).subscribe(response => {
    console.log("do my stuff");
    console.log(response)
   })

  } catch (error) {
    console.log("Hi")

  }
 // this.appcomponent.addButton()
  }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}
  //  subscribe(data =>{
  //         //console.log(postdata2)
  //         error => this.logError(filename, error)
  //         this.generated.next(true);
  //          })
           //setTimeout(()=>{ return this.statuspoint }, 4000)
          // return this.statuspoint
  // subscribe((res : any) => {
  //      console.log(res.body)
  //    });
  // .subscribe((res : any) => {
  //   console.log(res.text)
  // });


createversion(datarecord){
  let recorddata= datarecord
  delete recorddata["LastUpdate"]
  delete recorddata["RecordCreated"]
  delete recorddata["ReportGeneratedOn"]
  console.log(recorddata)
  console.log("Hi")
  this.createver = String(environment.backendUrl + "/api/v1/resources/response/createversion/"+this.selectedresponse)
   this.httpclient.put(this.createver, recorddata,this.httpOptions).
   subscribe(response => {
    console.log("do my stuff");
    console.log( response)
    this.selectedresponse=response["RespondentID"].toUpperCase()
    this.selectedindex=String(response["Version"])
    this.generateurl = String(environment.backendUrl + "/api/v1/resources/generateReport/"+response["RespondentID"]+"/"+response["Version"])
    this.generated.next(true);
    console.log(this.generateurl)
   })
 }

generatereport(url:string) {
  return this.httpclient.post(this.generateurl, this.GENERATE_DATA, this.httpOptions);
}

responsetrue(){
  return "Success"
}

selectedpath(selectedresponse,selectedindex,noofversion){
  this.newform = false
  this.addmodify=true
  this.noofversion = noofversion
  this.selectedresponse = selectedresponse.toUpperCase();
  console.log(selectedindex,"selectedindex")
  this.selectedindex = String(selectedindex);
  this.generateurl = String(environment.backendUrl + "/api/v1/resources/generateReport/"+selectedresponse+"/"+selectedindex)
}

  createrecord(datarecord) {
    let recorddata = datarecord

    delete recorddata["LastUpdate"]
    delete recorddata["RecordCreated"]
    delete recorddata["ReportGeneratedOn"]

    this.httpclient.put(environment.backendUrl + "/api/v1/resources/response/createversion/NewRequest", datarecord, this.httpOptions)
      .subscribe(response => {
        this.selectedresponse = response["RespondentID"].toUpperCase()
        this.generated.next(true);
        this.generateurl = String(environment.backendUrl + "/api/v1/resources/generateReport/" + response["RespondentID"] + "/" + 1)
      })
  }

newrecordhide(){
  this.newform = true
}
newrecordstatus(){
  return this.newform
}
addveropt(){
  return this.noofversion
}
sendrespondent(){
//  alert(this.generateurl)
  return this.selectedresponse
}
pdflink(){
  return String("../../assets/Data/"+this.selectedresponse+"_"+this.selectedindex+"/REAT_"+this.selectedresponse+"_"+this.selectedindex+".pdf")
}
eahtmllink(){
  return String("../../assets/Data/"+this.selectedresponse+"_"+this.selectedindex+"/REAT_"+this.selectedresponse+"_"+this.selectedindex+".html")
}

fileExists(url: string){

  return this.httpclient.get(url)


}






}
