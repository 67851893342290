import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import {LoginComponent} from '../login/login.component'
import { LocaldataserviceService } from '../localdataservice.service';
import { AppComponent } from '../app.component';
import { PlatformLocation } from '@angular/common'

@Component({
  selector: 'app-module-component',
  templateUrl: './module-component.component.html',
  styleUrls: ['./module-component.component.css']
})
export class ModuleComponentComponent implements OnInit {

  constructor( private router: Router, private localService: LocaldataserviceService,private appcomp: AppComponent,private location: PlatformLocation) { 
    
    location.onPopState(() => {
      console.log('pressed back!');
      this.router.navigateByUrl("/")
  

  });

  }

  ngOnInit(): void {
  }

  redirect_Epic(){

    let link = "https://epic.armstrongfluidtechnology.com/Project_Selec.html"
    window.location.href=link;
    
  }

  callEA(){
    this.router.navigateByUrl("/ea-module")
    
  }

  callroute(){
    this.router.navigateByUrl("/REAT")
  }

  back():void{
    this.location.back()
  }
  

}
