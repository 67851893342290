<a id="userguide" align="right" style="margin-left:990px; margin-bottom:-100px; color: red;" href="../assets/REAT repor printing user guide 09-01-23.pdf">USER-GUIDE</a>



<div class="container">
    <div id="print-select" >
          <div class="user-details" (change) = "verifyvalues()">
            <h4>Site audit tool data</h4><input style="display: inline;"  [disabled]="disablemodify" (click)="enableupdate()" type="checkbox" *ngIf="formrespask" [(ngModel)]="editInput"  ><label *ngIf="formrespask" style="display: inline;font-weight: bold;"> Modify Details</label>
            <br><br><h5 class="detailtab">Unit System</h5><br>
            <select (change)="Multiunitselec()"  [disabled]="!editInput" [(ngModel)]="multiutobj.system"><option selected value="metric">Metric</option><option  value="imperial">Imperial</option></select>
            <br>
            <table hidden class="paramtable unittable">
              <tr>
                  <td>Flow</td>
                  <td>Pressure/Head</td>
                  <td>Temperature</td>
                  <td>Load</td>
                  <td>Power</td>
                  </tr>
              <tr>
                  <td><select class="inputunit" [disabled]="!editInput" [(ngModel)]="multiutobj.Flowunit"><option value="US gpm">US gpm</option><option value="L/s">L/s</option><option value="m3/hr">m3/hr</option></select></td>
                  <td><select class="inputunit" [disabled]="!editInput" [(ngModel)]="multiutobj.headunit"><option value="ft">ft</option><option value="psi">psi</option><option value="kPa">kPa</option><option value="m">m</option><option value="bar">bar</option></select></td>
                  <td><select class="inputunit" [disabled]="!editInput"  [(ngModel)]="multiutobj.tempunit"><option value="Fahrenheit">&#8457;</option><option value="Celsius">&#8451;</option></select></td>
                  <td><select class="inputunit" [disabled]="!editInput"  [(ngModel)]="multiutobj.Loadunit"><option value="Ton">Ton</option><option value="kW">kW</option></select></td>
                  <td><select class="inputunit"  [disabled]="!editInput"  [(ngModel)]="multiutobj.Powunit"><option value="HP">HP</option><option value="kW">kW</option></select></td>
                </tr>
              <tr>
                <td colspan="5">*Note: This table is not mandatory to use, user can choose/change multiple input unit</td>
              </tr>
              <tr>
                <td colspan="5"><input [(ngModel)]="multiunit" (change)="Multiunitselec()" type="checkbox"> Apply Selected Units</td>
              </tr>

          </table>


            <br><br><h5 class="detailtab">Project Details</h5>
            <table class="paramtable">
              <tr>
                  <td>Project Name </td>
                  <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.ProjectName"></td>
                  <td>Commercial Contact </td>
                  <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.SalesPerson"></td>
                  </tr>
              <tr>
                  <td>Country </td>
                  <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.Country"></td>
                  <td>State </td>
                  <td><input style="text-transform: capitalize;" class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.State"></td>
                  </tr>
              <tr>
                  <td>City </td>
                  <td><input class="inputvalue " [disabled]="!editInput"   [(ngModel)]="tempData.Weathercity"></td>
                  <td>Building Type </td>
                  <td><select class= "inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.BuildingLoadProfile">
                      <option value="Apart-Condo, High Rise (10  stories)">Apart-Condo, High Rise (10+ stories)</option>
                      <option value="Apart-Condo, Low Rise (10 stories)">Apart-Condo, Low Rise (10 stories)</option>
                      <option value="Critical Care / Blood Banks">Critical Care / Blood Banksr</option>
                      <option value="Data Center">Data Center</option>
                      <option value="Elementary/Secondary School">Elementary/Secondary School</option>
                      <option value="Religious Institution">Religious Institution</option>
                      <option value="Factory / Warehouse">Factory / Warehouse</option>
                      <option value="Laboratory, R&D">Laboratory, R&D</option>
                      <option value="Hospital">Hospital</option>
                      <option value="Hotel">Hotel</option>
                      <option value="Mixed use: Commercial ">Mixed use: Commercial & Residential</option>
                      <option value="Office">Office</option>
                      <option value="Outpatient Healthcare">Outpatient Healthcare</option>
                      <option value="University/College">University/College</option>
                      <option value="Other (Manual Entry)">Other (Manual Entry)</option></select></td>
                  </tr>
              <tr>
                  <td>Energy Rate </td>
                  <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.PowerCost"></td>
                  <td>Currency </td>
                  <td><select class= "inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['Currency']">
                      <option value="USD">USD</option>
                      <option value="CAD">CAD</option>
                      <option value="INR">INR</option>
                      <option value="GBP">GBP</option>
                      <option value="CHN">CHN</option>
                      <option value="EUR">EUR</option>
                      </select></td>
                  </tr>
              <tr>
                  <td>Hours per Day </td>
                  <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.HoursPerDay"></td>
                  <td>Month per Year </td>
                  <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.MonthPerYear"></td>
                  </tr>
              <tr>
                  <td>Days per Week </td>
                  <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.DaysPerWeek"></td>
                  <td>System Installed Year </td>
                  <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.SystemYearInstalled"></td>
                  </tr>
                  <tr>
                    <td>Weather City </td>
                    <td><select (change)="checkcitylist()" title="Please select nearest city to project" class="inputvalue" [disabled]="!editInput"    [(ngModel)]="tempData.CityName" [ngClass]="{'cityerror': !weatherselecsts}">
                      <option *ngFor="let key of objectKeys(weathercity)" [value]="weathercity[key]">{{key}}</option></select>
                    </td>
                    <td>Contact No.</td>
                  <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.PhoneNo"></td>

                    </tr>
                    <tr>
                      <td colspan="4">*Mandatory: Please select Weather city from above list nearest to project.</td>
                      </tr>
          </table>

          <br><br><h5 class="detailtab">Base Case Details</h5>
          <table class="paramtable">
              <tr style="background-color: darkgrey !important">
                <td  colspan="4" style="font: bolder;">Chiller Details</td>
              </tr>
              <tr>
                  <td>Distribution Circuit Configuration</td>
                  <td><select (change)="disablesec()" class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['DistributionCircuitConfiguration']">
                    <option value="ConstantPrimary">Constant Primary ONLY</option>
                    <option value="ConstantPrimaryConstantSecondary">Constant primary/Constant Secondary</option>
                    <option value="ConstantPrimaryVariableSecondary">Constant Primary/Variable Secondary</option>
                    <option value="VariablePrimary">Variable Primary Only</option>
                    <option value="VariablePrimaryVariableSecondary">Variable Primary/Variable Secondary</option>
                  </select></td>
                  <td>Chiller Model </td>
                  <td><select class= "inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['BaseCaseChillerModel']">
                    <option value="TypicalConstantSpeedCentrifugalChiller">Typical Constant Speed Centrifugal Chiller</option>
                    <option value="TypicalVariableSpeedCentrifugalChiller">Typical Variable Speed Centrifugal Chiller</option>
                    <option value="OilFreeMagneticBearingVariableSpeedCentrifugalChiller">Typical Oil Free Mag Bearing Variable Speed Centrifugal Chiller</option>
                    </select></td>
              </tr>
              <tr>
                  <td>Number of Chillers </td>
                  <td><input class="inputvalue" [disabled]="!editInput"  [(ngModel)]="tempData['BaseCaseNumberOfChillers']" ></td>
                  <td>Number of Duty Chillers </td>
                  <td><input class="inputvalue" [disabled]="!editInput"  [(ngModel)]="tempData['BaseCaseNumberOfDutyChillers']" ></td>
              </tr>
              <tr>
                <td>Condenser Design DeltaT </td>
                <td><input class="inputvalue1" [disabled]="!editInput" [(ngModel)]="tempData.BaseCaseCondenserDesignDeltaT" ><select *ngIf="!considerobser" class="inputunit1" [disabled]="!editInput"  [(ngModel)]="tempData.InputBaseCaseCondenserDesignDeltaTUnit"><option value="Fahrenheit">&#8457;</option><option value="Celsius">&#8451;</option></select>
                  </td>
                <td>Evaporator Design DeltaT </td>
                <td>
                  <input class="inputvalue1" [disabled]="!editInput" [(ngModel)]="tempData.BaseCaseEvaporatorDesignDeltaT"   ><select *ngIf="!considerobser" class="inputunit1" [disabled]="!editInput"  [(ngModel)]="tempData.InputBaseCaseEvaporatorDesignDeltaTUnit"><option value="Fahrenheit">&#8457;</option><option value="Celsius">&#8451;</option></select>
                  </td>
              </tr>
              <tr>
                  <td>NamePlate Capacity (per Chiller) </td>
                  <td><input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData.BaseCaseNamePlateCapacity"><select class="inputunit1" [disabled]="!editInput"  [(ngModel)]="tempData.InputBaseCaseNamePlateCapacityUnit"><option value="Ton">Ton</option><option value="kW">kW</option></select></td>
                  <td>Chiller Min Ecwt </td>
                  <td><input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData.BaseCaseChillerMinEcwt"><select class="inputunit1" [disabled]="!editInput"  [(ngModel)]="tempData.InputBaseCaseChillerMinEcwtUnit"><option value="Fahrenheit">&#8457;</option><option value="Celsius">&#8451;</option></select></td>
                </tr>
                <tr>
                  <td>Design Day ChwstSP </td>
                  <td><input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData.BaseCaseDesigndayChwstSp"><select class="inputunit1" [disabled]="!editInput"  [(ngModel)]="tempData.InputBaseCaseDesigndayChwstSpUnit"><option value="Fahrenheit">&#8457;</option><option value="Celsius">&#8451;</option></select></td>
                  <td>Design Day Cooling Load </td>
                  <td><input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData.DesignDayCoolingLoad"><select class="inputunit1" [disabled]="!editInput"  [(ngModel)]="tempData.InputDesignDayCoolingLoadUnit"><option value="Ton">Ton</option><option value="kW">kW</option></select></td>
                </tr>
                <tr>
                <td style="height: 25px;" colspan="4" >
                </td>
            </tr>
            <tr style="background-color: darkgrey !important">
              <td colspan="4" style="font: bolder;">Cooling Tower Details</td>
            </tr>
            <tr>
                  <td>Number of Cooling Towers </td>
                  <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.NumberOfCoolingTowers"></td>
                  <td>Total Number of Fans  </td>
                  <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['TotalNumberOfFans']"></td>
                  </tr>
            <tr>
              <td>Fan Motor Type </td>
              <td><select class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['FanMotorType']"><option value="Constant Speed">Constant Speed</option><option value="Variable Speed">Variable Speed</option></select></td>
              <td>Horse Power per Fan </td>
              <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.HorsePowerPerFan"></td>
            </tr>
            <tr>
              <td>Tower Reset Temperature </td>
              <td><input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData.TowerResetTemperature"><select class="inputunit1" [disabled]="!editInput"  [(ngModel)]="tempData.InputTowerResetTemperatureUnit"><option value="Fahrenheit">&#8457;</option><option value="Celsius">&#8451;</option></select></td>
              <td colspan="2"></td>
            </tr>
            <tr>
              <td style="height: 25px;" colspan="4" >
              </td>
            </tr>
            <tr style="background-color: darkgrey !important">
              <td colspan="4" style="font: bolder;">Observed Base Details</td>
            </tr>
            <tr>
              <td>Condenser Observed DeltaT* </td>
              <td><input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData.observedCWDeltaT"><select *ngIf="!considerobser" class="inputunit1" [disabled]="!editInput"  [(ngModel)]="tempData.observedCWDeltaTUnit"><option value="Fahrenheit">&#8457;</option><option value="Celsius">&#8451;</option></select>
                </td>
              <td>Evaporator Observed DeltaT* </td>
              <td>
                <input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData.observedCHWDeltaT"><select *ngIf="!considerobser" class="inputunit1" [disabled]="!editInput"  [(ngModel)]="tempData.observedCHWDeltaTUnit"><option value="Fahrenheit">&#8457;</option><option value="Celsius">&#8451;</option></select>
                </td>
            </tr>
            <tr>
              <td>Use Observed Temperature* </td>
            <td><select class="inputvalue1" [disabled]="!editInput"  [(ngModel)]="tempData.useObsValue"><option value="True">Yes</option><option value="False">No</option></select></td>
              <td colspan="2"></td>
            </tr>
            <tr><td style="height: 25px;" colspan="4"></td></tr>
            <tr [ngClass]="{'observed': !obselecsts}">
              <td colspan="4"><input  (click)="open(mymodal)" type="button" style="width: 100%;height: 100%;text-align: center;" value="***READ THIS FIRST***"></td>
            </tr>
            <tr>
              <td>Observed Annual kWH* </td>
              <td><input  class="inputvalue1" [disabled]="!editInput || obkWH" (change) = "observedpara()"  [(ngModel)]="tempData.ObservedBaseCasekWh">
                </td>
              <td>Observed Annual Ton-Hrs* </td>
                <td><input class="inputvalue1" [disabled]="!editInput || obton" (change) = "observedpara()"  [(ngModel)]="tempData.ObservedPlantTonHours">
                  </td>
              </tr>
            <tr>
              <td>Observed Plant Efficinecy* </td>
              <td>
                <input class="inputvalue1" [disabled]="!editInput || obeff" (change) = "observedpara()"  [(ngModel)]="tempData.ObservedBaseCaseEfficiency">
                </td>
                <td>Select Constant Parameter* </td>
                <td>
                  <select ng-message="If anyone parameter from Efficiency, kWH or Ton-hrs available, choose from list to keep one constant" class= "inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['SolutionOption']">
                    <option *ngIf= "tempData.ObservedBaseCasekWh == 0 || tempData.ObservedBaseCasekWh == '' || tempData.ObservedBaseCasekWh == 0.0" value="kWH">Base kWH</option>
                    <option *ngIf= "tempData.ObservedPlantTonHours == 0 || tempData.ObservedPlantTonHours == '' || tempData.ObservedPlantTonHours == 0.0" value="Ton-Hrs">Base Ton-Hrs</option>
                    <option *ngIf= "tempData.ObservedBaseCaseEfficiency == 0 || tempData.ObservedBaseCaseEfficiency == '' || tempData.ObservedBaseCaseEfficiency == 0.0" value="Efficiency">Base Efficiency</option>
                    </select>
                </td>
              </tr>
              <tr>
              <td style="height: 25px;" colspan="4" style="font-size: 5px bolder;">*Note: Observed details are not mandatory fields
              </td>
          </tr>



          </table>

          <br><br><h5 class="detailtab">Optimized Case Details</h5>
          <table class="paramtable">
              <tr style="background-color: darkgrey !important">
                <td  colspan="4" style="font: bolder;">Chiller Details</td>
              </tr>
              <tr>
                  <td>Chiller Model </td>
                  <td><select class= "inputvalueo" [disabled]="!editInput"   [(ngModel)]="tempData['OptimizedCaseChillerModel']">
                    <option value="TypicalConstantSpeedCentrifugalChiller">Typical Constant Speed Centrifugal Chiller</option>
                    <option value="TypicalVariableSpeedCentrifugalChiller">Typical Variable Speed Centrifugal Chiller</option>
                    <option value="OilFreeMagneticBearingVariableSpeedCentrifugalChiller">Typical Oil Free Mag Bearing Variable Speed Centrifugal Chiller</option>
                    </select></td>
                    <td>Number of Chillers </td>
                    <td><input class="inputvalueo" [disabled]="!editInput"   [(ngModel)]="tempData['OptimizedCaseNumberOfChillers']"></td>
              </tr>
              <tr>
                <td>NamePlate Capacity (per Chiller) </td>
                    <td><input class="inputvalueo1" [disabled]="!editInput"   [(ngModel)]="tempData.OptimizedCaseNamePlateCapacity"><select class="inputunit1" [disabled]="!editInput"  [(ngModel)]="tempData.InputOptimizedCaseNamePlateCapacityUnit"><option value="Ton">Ton</option><option value="kW">kW</option></select></td>
                  <td>Number of Duty Chillers </td>
                  <td><input class="inputvalueo" [disabled]="!editInput"   [(ngModel)]="tempData['OptimizedCaseNumberOfDutyChillers']"></td>
              </tr>
              <tr>
                <td>Condenser Design DeltaT </td>
                <td><input class="inputvalueo1" [disabled]="!editInput"   [(ngModel)]="tempData.OptimizedCaseCondenserDesignDeltaT"><select *ngIf="!considerobser" class="inputunit1" [disabled]="!editInput"  [(ngModel)]="tempData.InputOptimizedCaseCondenserDesignDeltaTUnit"><option value="Fahrenheit">&#8457;</option><option value="Celsius">&#8451;</option></select>
                  </td>
                <td>Evaporator Design DeltaT </td>
                <td>
                  <input class="inputvalueo1" [disabled]="!editInput"   [(ngModel)]="tempData.OptimizedCaseEvaporatorDesignDeltaT"><select *ngIf="!considerobser" class="inputunit1" [disabled]="!editInput"  [(ngModel)]="tempData.InputOptimizedCaseEvaporatorDesignDeltaTUnit"><option value="Fahrenheit">&#8457;</option><option value="Celsius">&#8451;</option></select>
                  </td>
              </tr>
              <tr>
                  <td>Chiller Min Ecwt </td>
                  <td><input class="inputvalueo1" [disabled]="!editInput"   [(ngModel)]="tempData.OptimizedCaseChillerMinEcwt"><select class="inputunit1" [disabled]="!editInput"  [(ngModel)]="tempData.InputOptimizedCaseChillerMinEcwtUnit"><option value="Fahrenheit">&#8457;</option><option value="Celsius">&#8451;</option></select></td>
                  <td>Design Day ChwstSP </td>
                  <td><input class="inputvalueo1" [disabled]="!editInput"   [(ngModel)]="tempData.OptimizedCaseDesigndayChwstSp"><select class="inputunit1" [disabled]="!editInput"  [(ngModel)]="tempData.InputOptimizedCaseDesigndayChwstSpUnit"><option value="Fahrenheit">&#8457;</option><option value="Celsius">&#8451;</option></select></td>
                </tr>
          </table>

          <br><br><h5 class="detailtab">Primary Pump</h5>
          <table class="paramtable">
              <tr>
                <td>Variable Speed </td>
                <td><select class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['PrimaryVariableSpeed']"><option value="Yes">Yes</option><option value="No">No</option></select></td>
                <td>Pump Control Method </td>
                <td><select class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['PrimaryPumpControlMethod']"><option value="Remote dP Sensor">Remote dP Sensor</option><option value="Remote Temp Sensor">Remote Temp Sensor</option><option value="Local dP Sensor">Local dP Sensor</option><option value="Local Temp Sensor">Local Temp Sensor</option><option value="Sensorless">Sensorless</option><option value="Other (Manual Entry)">Other (Manual Entry)</option></select></td>
            </tr>
            <tr>
                <td>Year Installed </td>
                <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['PrimaryYearInstalled']"></td>
                <td>System Configuration </td>
                <td><select class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['PrimaryPumpSystemConfiguration']"><option value="Headered">Headered</option><option value="Dedicated">Dedicated</option></select></td>
            </tr>
            <tr>
                <td>Number of Pumps </td>
                <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.PrimaryPumpNumber"></td>
                <td></td>
                <td></td>
                </tr>
            <tr>
              <td style="height: 25px;" colspan="4">
              </td>
          </tr>
          <tr>
          <td>Design Head </td>
          <td><input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData.PrimaryHead"><select class="inputunit1" [disabled]="!editInput" [(ngModel)]="tempData.InputPrimaryHeadUnit"><option value="ft">ft</option><option value="psi">psi</option><option value="kPa">kPa</option><option value="m">m</option><option value="bar">bar</option></select></td>
          <td>Design Flow </td>
          <td colspan="2"><input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData.PrimaryMaxFlow"><select class="inputunit1" [disabled]="!editInput" [(ngModel)]="tempData.InputPrimaryMaxFlowUnit"><option value="US gpm">US gpm</option><option value="L/s">L/s</option><option value="m3/hr">m3/hr</option></select></td>
        </tr>
        <tr>
          <td>Nameplate Motor Power <img *ngIf="warnpri" src="../../assets/warn.png" style="height: 30px;width: 30px;" class="blink" title="*Warning: Motor nameplate power is less than pump required power"></td>
          <td><input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData['PrimaryMotorPowerOnNameplate']"><select class="inputunit1"  [disabled]="!editInput"  [(ngModel)]="tempData['PrimaryInputMotorPowerOnNameplateUnit']"><option value="HP">HP</option><option value="kW">kW</option></select></td>
          <td colspan="2"></td>
      </tr>
          </table>

          <div *ngIf= "enablesec">
          <br><br><h5 class="detailtab">Secondary Pump</h5>
      <table class="paramtable">
                <tr>
                  <td>Variable Speed </td>
                  <td><select class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['SecondaryVariableSpeed']"><option value="Yes">Yes</option><option value="No">No</option></select></td>
                  <td>Pump Control Method </td>
                  <td><select class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['SecondaryPumpControlMethod']"><option value="Remote dP Sensor">Remote dP Sensor</option><option value="Remote Temp Sensor">Remote Temp Sensor</option><option value="Local dP Sensor">Local dP Sensor</option><option value="Local Temp Sensor">Local Temp Sensor</option><option value="Sensorless">Sensorless</option><option value="Other (Manual Entry)">Other (Manual Entry)</option></select></td>
              </tr>
              <tr>
                  <td>Year Installed </td>
                  <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['SecondaryYearInstalled']"></td>
                  <td>Pump System Configuration </td>
                  <td><select class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['SecondaryPumpSystemConfiguration']"><option value="Headered">Headered</option><option value="Dedicated">Dedicated</option></select></td>
              </tr>
              <tr>
                  <td>Number of Pump </td>
                  <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.SecondaryNumber"></td>
                  <td></td>
                  <td></td>
              </tr>
              <tr>
                <td style="height: 25px;" colspan="4">
                </td>
            </tr>
            <tr>
            <td>Design Head </td>
            <td><input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData.SecondaryHead"><select class="inputunit1" [disabled]="!editInput" [(ngModel)]="tempData.InputSecondaryHeadUnit"><option value="ft">ft</option><option value="psi">psi</option><option value="kPa">kPa</option><option value="m">m</option><option value="bar">bar</option></select></td>
            <td>Design Flow </td>
            <td colspan="2"><input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData.SecondaryMaxFlow"><select class="inputunit1" [disabled]="!editInput" [(ngModel)]="tempData.SecondaryMaxFlowInputUnit"><option value="US gpm">US gpm</option><option value="L/s">L/s</option><option value="m3/hr">m3/hr</option></select></td>
          </tr>
          <tr>
            <td>Nameplate Motor Power </td>
            <td><input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData['SecondaryMotorPowerOnNameplate']"><select class="inputunit1"  [disabled]="!editInput"  [(ngModel)]="tempData['SecondaryInputMotorPowerOnNameplateUnit']"><option value="HP">HP</option><option value="kW">kW</option></select></td>
            <td colspan="2"></td>
        </tr>
            </table>
          </div>

            <br><br><h5 class="detailtab">Condenser Pump</h5>
              <table class="paramtable">
                  <tr>
                    <td>Variable Speed </td>
                    <td><select class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['CondenserVariableSpeed']"><option value="Yes">Yes</option><option value="No">No</option></select></td>
                    <td>Pump Control Method </td>
                    <td><select class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['CondenserPumpControlMethod']"><option value="Remote dP Sensor">Remote dP Sensor</option><option value="Remote Temp Sensor">Remote Temp Sensor</option><option value="Local dP Sensor">Local dP Sensor</option><option value="Local Temp Sensor">Local Temp Sensor</option><option value="Sensorless">Sensorless</option><option value="Other (Manual Entry)">Other (Manual Entry)</option></select></td>
                </tr>
                <tr>
                    <td>Year Installed </td>
                    <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['CondenserYearInstalled']"></td>
                    <td>Pump System Configuration </td>
                    <td><select class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData['CondenserPumpSystemConfiguration']"><option value="Headered">Headered</option><option value="Dedicated">Dedicated</option></select></td>
                </tr>
                <tr>
                    <td>Number of Pump </td>
                    <td><input class="inputvalue" [disabled]="!editInput"   [(ngModel)]="tempData.CWNumber"></td>
                    <td colspan="2"></td>
                    </tr>
                <tr>
                  <td style="height: 25px;" colspan="4">
                  </td>
              </tr>
              <tr>
              <td>Design Head </td>
              <td><input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData.CWHead"><select class="inputunit1" [disabled]="!editInput" [(ngModel)]="tempData.CWHeadInputUnit"><option value="ft">ft</option><option value="psi">psi</option><option value="kPa">kPa</option><option value="m">m</option><option value="bar">bar</option></select></td>
              <td>Design Flow </td>
              <td colspan="2"><input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData.CWMaxFlow"><select class="inputunit1" [disabled]="!editInput" [(ngModel)]="tempData.CWMaxFlowInputUnit"><option value="US gpm">US gpm</option><option value="L/s">L/s</option><option value="m3/hr">m3/hr</option></select></td>
            </tr>
            <tr>
              <td>Nameplate Motor Power </td>
              <td><input class="inputvalue1" [disabled]="!editInput"   [(ngModel)]="tempData['CondenserMotorPowerOnNameplate']"><select class="inputunit1"  [disabled]="!editInput"  [(ngModel)]="tempData['CondenserInputMotorPowerOnNameplateUnit']"><option value="HP">HP</option><option value="kW">kW</option></select></td>
              <td colspan="2"></td>
          </tr>
              </table>

      <br><br>

          </div>
          <div >
            <span style="color:black; display:inline">
            <button *ngIf="currentbut"  (click)="updateversion()"> Update Current Version </button>
            &nbsp;&nbsp;&nbsp;
            <button *ngIf="updatebut"  (click)="createnew()"> Create New Version </button>
            </span><br>
            <button (click)="savedata()" style="margin-top: 10px;">Save Changes</button>
          </div>
          <br><br>
          <table class="report-table">
            <tr>
              <th>Response ID</th>
              <th>Report Generated on</th>
              <th>Action</th>
            </tr>
            <tr>
              <td>&nbsp;{{respondentId}}</td>
              <td><div *ngIf="updatedet" style="height: 100%;"><img style="height: 15px;" src="assets/loading2.gif" alt="loading Gif"><label> Time left::&nbsp;<countdown [config]="{leftTime:400}" (event)="handleEvent($event)"></countdown></label></div><label *ngIf="!updatedet">{{reportstatus}}</label></td>
              <td><button *ngIf="generated" (click)="Help(mymodal1)">Generate Report</button><button *ngIf="downloadbut"  routerLink="../ea-module/viewreport">View Report</button></td>
            </tr>
          </table>
          <div class="btn-div">
            <button class="blue-button" routerLink="" (click)="back()">Back</button>
          </div>
        </div>
      </div>


      <ng-template #mymodal let-modal >
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Instructions:</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          The REAT is now able to incorporate the following observed plant values in our analysis:
<br>
1.	Observed annual plant kWh consumption<br>
2.	Observed annual plant ton-hr output<br>
3.	Observed annual plant efficiency (kW/Ton)<br>
<br>
We do this by scaling our analysis based on these observed values. To ensure this works seamlessly, please read the below notes:
<br>
1.	A maximum of 2/3 observed values can be input at any time. For example - if you have the plant’s annual kWh consumption and ton-hr output, do not enter the observed efficiency since that will be calculated.<br>
2.	In cases where you have 1/3 observed values, you will need to select one of the two OTHER values as a “Constant”. We require this input to ensure we provide one solution. For example – if you have the plant’s annual kWh consumption, this information can be used to scale the plant efficiency (keeping cooling output constant) or the scale the cooling output (keeping plant efficiency constant). Each method will produce a different result.<br>
3.	The ideal way to use this functionality is to first generate a report WITHOUT observed values, and then regenerate the report knowing which value you would like to keep constant (if necessary). In cases where you provide 2/3 values, the “Constant” parameter is not required.

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
        </div>
      </ng-template>

      <ng-template #mymodal1 let-modal >
        <div class="modal-header modhead">
          <h4 class="modal-title" id="modal-basic-title1">Please Correct Below Fields:</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <table id="myTable">
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
        </div>
      </ng-template>

      <style>
        @media screen and (min-width: 676px) {

            .modal-dialog {
              max-width: 900px; /* New width for default modal */
              margin: auto;
            }
            .modal-dialog~.modal-dialog{
              max-width: 500px; /* New width for default modal */
              max-height: 100px;
              margin: auto;
            }
            .modal-body~.modal-body table,.modal-body~.modal-body table tr,.modal-body~.modal-body table tr td{
               border: 0px !important;
            }
            .modal-header{
              background-color: #00a8e1;
            }
            .modhead{
              background-color: red !important;
            }
        }
    </style>
