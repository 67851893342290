import { Component, OnInit } from '@angular/core';
import { LocaldataserviceService } from '../localdataservice.service';
import { PlatformLocation } from '@angular/common'
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  subscription: Subscription;
  idInput: boolean = false;
  editInput: boolean = false;
  loginput: boolean = false
  respondentId: string;
  withforms: any;
  id: any;
  formrespask: boolean = false;
  credid: string = "@armstrongfluidtechnology.com"
  credpass: string = ""
  dataList;
  respondentDetailsObserve;
  jsonStr;
  public dbdict: any[] = [];
  public tempData
  public tempData_req = "Hi";//[{"HI":123,"HECK":4532}]
  public postdatareq;
  public selectedversion;
  private _url: string;

  constructor(private _flaskservice: LocaldataserviceService, location: PlatformLocation, private router: Router) {
    location.onPopState(() => {
      this.idInput = true;
      this.pagerefresh()
    });
  }

  next(respondentId: string, versionId: number) {
    this._url = respondentId;
    this.idInput = false;
    const dbelement = this.dbdict.find(x => x.RespondentID === respondentId && x.Version === versionId);
    this.tempData = JSON.parse(JSON.stringify(dbelement));
    this._flaskservice.selectedpath(respondentId, String(versionId), versionId)
    this.submitdata()
  }

  new_form() {
    this.loginput = false;
    this.tempData = this._flaskservice.demodata()
    this.tempData["Email"] = this.credid
    this._flaskservice.newrecordhide();
    this.submitdata()
    this.router.navigateByUrl('/module-component');
  }
  form_confirm() {
    if (this.withforms == "Yes") {
      this.formrespask = true;
    }
    else {
      this.formrespask = false;
    }
  }

  async loadDatailsByUsername() {
    const email = sessionStorage.getItem("username");
    if (email) {
      const response = await this._flaskservice.getRespondentDetailsByEmail(email);
      this.setRespondentList(response);
    }
  }

  async addButton() {
    const response = await this._flaskservice.getRespondentDetails(this.respondentId);
    this.setRespondentList(response);
  }

  setRespondentList(list: any[]) {
    // sort by respondent id then by version
    this.dbdict = list.filter(x => !!x.RespondentID && x.RespondentID !== 'undefined').sort((a, b) => {
      if (a.RespondentID > b.RespondentID) return -1;
      if (a.RespondentID < b.RespondentID) return 1;
      if (a.Version > b.Version) return -1;
      if (a.Version < b.Version) return 1;
      return 0;
    });
  }

  back() {
    this.idInput = true;
  }

  accessverfy() {
    this.idInput = false

    // this.checkAccessToken();

    if (sessionStorage.getItem("username") && sessionStorage.getItem("accessToken")) {
      this.tempData = this._flaskservice.demodata()
      this.tempData["Email"] = sessionStorage.getItem("username")
      this._flaskservice.tempData = this.tempData
      this.router.navigate(['/module-component']);
    } else {
      this.loginput = true
    }
  }

  checkAccessToken() {
    const token = sessionStorage.getItem("accessToken");
    if (token) {
      const tokenParts = token.split('.');
      const tokenDecoded = JSON.parse(atob(tokenParts[1]));
      const tokenExp = tokenDecoded.exp;
      const tokenExpDate = new Date(tokenExp * 1000);
      const now = new Date();
      if (tokenExpDate < now) {
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("username");
        sessionStorage.removeItem("login-session");
      }
    }
  }

  credentialverify() {
    this._flaskservice.authenticate(this.credid, this.credpass).subscribe(response => {
      console.log("Authenticate Response")
      console.log(response['url'])
      console.log(Object.keys(response))

      if (response['url']) {
        window.location.href = response['url'];
      }
    }
    )
  }

  get SumValue() {
    return this.tempData;
  }

  setCode() {
    // this method uses for AD login only

    if (window.location.href.includes('?code=')) {
      const redirectUrl = environment.baseUrl + "/login";
      let applicationType = "AZURE.REAT.LOCAL";
      let code = window.location.href.split('?code=')[1];
      this._flaskservice.ADAuthorization(code, applicationType, redirectUrl).subscribe(
        Response => {
          if (Response.access_token !== undefined) {
            console.log('response', Response)
            sessionStorage.setItem("username", Response.email_id);
            this._flaskservice.UserLog(Response.email_id);
            sessionStorage.setItem("accessToken", Response.access_token);
            sessionStorage.setItem("login-session", "true");

            this.tempData = this._flaskservice.demodata()
            this.tempData["Email"] = Response.email_id
            this._flaskservice.tempData = this.tempData
            this.router.navigate(['/module-component']);
          } else { console.log("access token not received") }
        })
    }
  }


  pagerefresh() {
    this.router.navigateByUrl("/")
    this.loginput = false
  }

  submitdata() {
    this._flaskservice.tempData = this.tempData;
    this._flaskservice.editInput = this.editInput
    this._flaskservice.formrespask = this.formrespask
    this._flaskservice.respondentId = this.respondentId;
  }

  ngOnInit(): void {
    this.setCode()
    this.idInput = true
    this.router.navigate([''])
    this.respondentId = this._flaskservice.respondentId

    this.loadDatailsByUsername();
  }

}
