import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import  {browser, element, by, protractor} from  "protractor";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { GeneralService } from './generalservice.service';
import { LocaldataserviceService } from './localdataservice.service';
import { from } from 'rxjs';
import { REATComponent } from './REAT/REAT.component';
import {CountdownModule} from 'ngx-countdown';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { EAModuleComponent } from './ea-module/ea-module.component';
import { ModuleComponentComponent } from './module-component/module-component.component';
import { ViewreportComponent } from './ea-module/viewreport/viewreport.component';
import { CommonModule } from '@angular/common';

//import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

//import {PopupModule} from 'ng2-opd-popup'


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    REATComponent,
    EAModuleComponent,
    ModuleComponentComponent,
    ViewreportComponent,
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CountdownModule,
    NgbModule,
    CommonModule
   // NgbModule,
  ],
  providers: [GeneralService,LocaldataserviceService],
  bootstrap: [AppComponent]
})
export class AppModule { }
