<div class="container-fluid navbar navbar-default header">
  <link rel="stylesheet" type="text/css"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css">

  <div class="navbar-header">

    <a href="/"><span class="navbar-brand header-text">Risk & Energy Assessment Tool</span></a>
  </div>

  <div style="display: flex; align-items: center; gap: 20px;">
    <a *ngIf="isLoggedIn" href="javascript:void(0)" (click)="logout()">
      <i class="fa fa-sign-out" aria-hidden="true" style="color: white; font-size: 18px; font-weight: bold;">
        Logout
      </i>
    </a>
    <span class="nav navbar-nav navbar-right">
      <img src="assets/armstrong_logo.png" alt="Company Logo">
    </span>
  </div>
</div>
<router-outlet></router-outlet>

<!--<app-formdata1 *ngIf="!idInput" [model_data]="tempData">

</app-formdata1> -->
