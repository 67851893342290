import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { formComponent } from './form/form.component';
import { REATComponent } from './REAT/REAT.component';
import { ModuleComponentComponent } from './module-component/module-component.component';
import { EAModuleComponent } from './ea-module/ea-module.component';
import { ViewreportComponent } from './ea-module/viewreport/viewreport.component';
import {LoginComponent} from './login/login.component'
//import { AppComponent } from './app.component';

const routes: Routes = [
  { path: '', component: LoginComponent},
  { path: 'login', component: LoginComponent},
  { path: 'REAT', component: REATComponent},
  { path: 'module-component', component: ModuleComponentComponent},
  { path: 'ea-module', component: EAModuleComponent},
  { path: 'ea-module/viewreport', component: ViewreportComponent},
  //{ path: 'form1',component: Form1Component }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
//export const appRoutingModule = RouterModule.forRoot(routes);