import { Component, OnInit, Input } from '@angular/core';
import {AppComponent} from '../app.component'
import {LoginComponent} from '../login/login.component'
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { error } from '@angular/compiler/src/util';
import * as fs from 'file-saver';
import { HttpClient,HTTP_INTERCEPTORS,HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { LocaldataserviceService } from '../localdataservice.service';
import { HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { timer } from 'rxjs';
import { ElementFinder } from 'protractor';
import { PlatformLocation } from '@angular/common'
import { threadId } from 'worker_threads';
import {CountdownEvent, CountdownModule} from 'ngx-countdown'
import  weathercitylist from '../weathercity.json';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router'
import { ActivatedRoute } from '@angular/router'


// @NgModule({
//   imports:      [ BrowserModule],
//   declarations: [ AppComponent],  //<----here
//   providers:    [],
//   bootstrap:    [ AppComponent ]
// })

@Component({
  selector: 'app-REAT',
  templateUrl: './REAT.component.html',
  styleUrls: ['./REAT.component.css'],

})
export class REATComponent implements OnInit {

  closeResult: string;
  @Input() model_data: Object;
  public editInput: boolean = false;
  public formrespask: boolean = false;
  public reportbut: boolean = false;
  public generatebut: boolean = false
  public updatedet: boolean = false;
  public tempData;
  // public Observed_obj = {
  //   "observedCHWDeltaT" : this.tempData["BaseCaseEvaporatorDesignDeltaT"],
  //   "observedCHWDeltaTUnit": this.tempData["InputBaseCaseEvaporaterDesignDeltaTUnit"],
  //   "observedCWDeltaT": this.tempData["BaseCaseCondenserDesignDeltaT"],
  //   "observedCWDeltaTUnit": this.tempData["InputBaseCaseCondenserDesignDeltaTUnit"],
  //   "useObsValue": this.tempData["BaseCaseCondenserDesignDeltaT"]
  // }
  public savechanges:boolean = true;
  public respondentId;
  public disablemodify:boolean = false
  public data:any;
  public mymodel:any;
  public updatebut: boolean = true
  public currentbut: boolean = true;
  public savebut: boolean = false;
  public downloadbut: boolean;
  subscription: Subscription;
  generated: boolean;
  public reportstatus: any;
  public  timeleft: any;
  public countdown: any;
  objectKeys = Object.keys;
  public weathercity: any = weathercitylist
  public cityarray: any
  public weatherselecsts: boolean = true
  public considerobser: boolean = false;
  public obkWH: boolean;
  public obton: boolean;
  public obeff: boolean;
  public enablesec: boolean = true;
  public obselecsts: boolean = true
  public multiunit: boolean = false;
  public filepath: any;
  public status_verify: any;
  public warnpri: boolean = false;
  public multiutobj = {
    "tempunit": "Celsius",
    "headunit": "ft",
    "Flowunit": "US gpm",
    "Powunit": "HP",
    "Loadunit": "Ton",
    "system": "imperial"
  }

  constructor(private route: ActivatedRoute, private router: Router,private modalService: NgbModal, private _flaskservice: LocaldataserviceService, private appcomp: AppComponent,private location: PlatformLocation) {
      location.onPopState(() => {

        console.log('pressed back!');})
    this.tempData = this._flaskservice.tempData
    this.editInput = this._flaskservice.editInput
    this.formrespask = this._flaskservice.formrespask
    this.respondentId = this._flaskservice.respondentId;
    this.disablesec()
   }

   open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }


  handleEvent(event:CountdownEvent):void{ // notify action not trigger    
  console.log(event);
  if(event.action==='notify'){
     console.log(event);
    }else if (event.action === 'done') {
  //    alert('The timer has finished !');

 //     this.router.navigate(['/module-component']);
 this.location.back();

    }}



   back(){
    this._flaskservice.respondentId =this.respondentId
    // this.appcomp.pagerefresh()
    //  this.updatebut = false;
    //   this.currentbut = true;
    //   this.savebut = false;
    //   this.downloadbut=false;
    //   this.generated = false;
    //   this.disablemodify =true
   }
   GenerateRPT(){
    this.updatedet = true;
    this.generated = false;
    this.reportbut = false;
   // debugger;
    //let pdf_path = String("assets/Data/"+this.respondentId+"/REAT_"+this.respondentId+".pdf")
    let pdf_path = String("assets/Data/1091691987"+"/EPIC"+".pdf")
    let pdf_name = String("REAT_"+this.respondentId)
    let link = document.createElement("a");
    let downloadstatus: string
    link.download = pdf_name;
    this.downloadbut = false
    link.href = pdf_path;
    let counter = 1;
    let result


  //   setTimeout(() => {
  //     this.updatedet  = false
  //         this.downloadbut = true
  //         this.generated = false
  //         let date: Date = new Date();
  //         this.reportstatus= String(date)
  // }, 240000);
    this.respondentId =this._flaskservice.sendrespondent()
    this._flaskservice.generatereport("text").subscribe(response => {
        console.log("do my stuff");
        console.log(response)
        if(response["filePath"]=="Report failed"){
          //alert('Form got failed,Please contact REAT.armstrongfluidtechnology.com')
          this.downloadbut = false
          this.generated = true
          this.updatedet  = false
          this.reportstatus= 'Form got failed,Please contact REAT.armstrongfluidtechnology.com'
        }
        else{
          this.updatedet  = false
          this.downloadbut = true
          this.generated = false
          let date: Date = new Date();
          this.reportstatus= String(date)
        }
        //this.generateresponse = response

       })
    //alert("HEy")

//const anotherResult = await anotherPromise;
       }

  showbutton(val){
      //alert("Hello")
      this.updatedet = false;
      this.reportbut = true;
      this.generatebut = false;
    }
  disablesec(){
    if(this.tempData["DistributionCircuitConfiguration"] == "ConstantPrimary" || this.tempData["DistributionCircuitConfiguration"] == "VariablePrimary")
    {this.enablesec = false}
    else{this.enablesec = true}
 // this.enablesec = true
}

  verifyvalues(){
    for (let index = 0; index < Object.keys(this.tempData).length; index++) {
      if(this.tempData[Object.keys(this.tempData)[index]] == ""){
        this.tempData[Object.keys(this.tempData)[index]] = 0}
    }
    this.check_list()

  }

  updateversion(){
      this.reportbut = false;
      this.updatedet = true;
      this.generatebut = false
        //let pdf_path = String("assets/Data/"+this.respondentId+"/REAT_"+this.respondentId+".pdf")
        for (let i = 0; i < 1; i++) {
         this._flaskservice.updatedata(this.tempData)
        }
      this.reportbut = false;
      this.updatedet = false;
      this.generatebut = true;
      this.currentbut = false;
      this.updatebut = false;
      this.editInput = false;
 //     alert("Hello")
           }
    savedata(){

      this.reportbut = false;
      this.savebut = false
      this.updatedet = true;
      this.generatebut = false
            let pdf_path = String("assets/Data/"+this.respondentId+"/REAT_"+this.respondentId+".pdf")
           for (let i = 0; i < 1; i++) {
             this._flaskservice.createrecord(this.tempData)
            }
      this.reportbut = false;
      this.savebut = false;
      this.updatedet = false;
      this.generatebut = true;
      this.editInput = false;
               }
    createnew(){
                let pdf_path = String("assets/Data/"+this.respondentId+"/REAT_"+this.respondentId+".pdf")
                this.reportbut = false;
                this.updatedet = true;
                this.generatebut = false
                for (let i = 0; i < 1; i++) {
                 this._flaskservice.createversion(this.tempData)
             //console.log(this._flaskservice.createversion(this.tempData))
                }
                this.reportbut = false;
                this.updatedet = false;
                this.generatebut = true;
                this.currentbut = false;
                this.updatebut = false;
                this.editInput = false;
                //this.logcomp.addButton()
              }
   downloadPDF(){
    let pdf_path = String(this._flaskservice.pdflink())
    console.log(pdf_path)
    let pdf_name = String("REAT_"+this.respondentId)
    let link = document.createElement("a");
    link.download = pdf_name;
    link.href = pdf_path;
    link.click();
}

  changesopt(){
    this.savechanges = !this.savechanges
  }
  enableupdate(){
    if(this._flaskservice.newrecordstatus() == false && this._flaskservice.addveropt()>=5 ){
      this.updatebut = false;
      this.currentbut = true;
      this.savebut = false;
      this.downloadbut=false;
      this.generated = false;
      this.disablemodify =true
     }
    else if(this._flaskservice.newrecordstatus() == false && this._flaskservice.addveropt()<5){
      this.updatebut = true;
      this.currentbut = true;
      this.generated = false;
      this.downloadbut=false;
      this.savebut = true;    //this should be false.......
      this.disablemodify =true
      }
    else{
      this.updatebut = false;
      this.generated = false;
      this.downloadbut=false;
      this.currentbut = false;
      this.savebut = true;
      this.editInput=true;
      this.disablemodify =true
     }
  }

  checkcitylist(){
    this.cityarray = [];
    console.log(this.cityarray,this.weathercity,"here")
    for (let value of Object.values(this.weathercity)) {
      // this.cityarray.push(value)      -----------------------------this is pushing weathername to cityname>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      this.cityarray.push(value)
    }
    console.log("Tempdata",this.tempData['CityName'],this.cityarray)
    if(this.cityarray.includes(this.tempData['CityName'])){
      this.weatherselecsts = true
    }
    else{
      this.weatherselecsts = false
      this.enableupdate()
      this.editInput = true
    }
  }




  ngOnInit(): void {


    this.subscription = this._flaskservice.generated$.subscribe(generated => this.generated = generated);
    if(this.tempData!= null ){
    if(this._flaskservice.newrecordstatus() == false){
      this.updatebut = false;
      this.currentbut = false;
      this.savebut = true;
      this.downloadbut=false;
      this.generated =true;
      this.formrespask = true;
    }
    else{
      this.updatebut = false;
      this.currentbut = false;
      this.savebut = true;
      this.editInput=true;
      this.formrespask = false;
    }
    if( this.tempData["ReportGeneratedOn"]==null && this._flaskservice.newrecordstatus() == false){
      this.generated =true;
      this.downloadbut =false;
      this.reportstatus=""
    }
    else if( this.tempData["ReportGeneratedOn"]==null && this._flaskservice.newrecordstatus() == true){
      this.generated =false;
      this.downloadbut =false;
      this.reportstatus=""
    }
    else{
      if(this.tempData["ReportPath"]!=null && this.tempData["ReportPath"]!="Report failed"){
      this.generated =false;
      this.downloadbut =true;
      this.filepath = this._flaskservice.pdflink()
    }
    else{
      this.generated =false;
      this.downloadbut =false;
      this.updatebut = true;
      this.currentbut = true;
      this.editInput = true;
    }

    }
  }
  else{
    this._flaskservice.enablefirstpage()
    this.tempData = this._flaskservice.demodata
    this.editInput = true;
    }
    this.checkcitylist()
    this.observedpara()
    this.check_list()

   if(this.tempData["InputPrimaryMaxFlowUnit"] == "US gpm"){this.multiutobj["system"] = "imperial"}
   else{this.multiutobj["system"] = "metric"}

   this.tempData["ReportType"] ="REAT"
  }

  observedpara(){
    var x=0;
    if(Number(this.tempData.ObservedBaseCasekWh)>0){x=x+1}
    if(Number(this.tempData.ObservedPlantTonHours)>0){x=x+1}
    if(Number(this.tempData.ObservedBaseCaseEfficiency)>0){x=x+1}
    if(x==2){
      if(Number(this.tempData.ObservedBaseCasekWh)<=0){this.obkWH=true}
      if(Number(this.tempData.ObservedPlantTonHours)<=0){this.obton=true}
      if(Number(this.tempData.ObservedBaseCaseEfficiency)<=0){this.obeff=true}
      this.obselecsts = true
      console.log()
    }
    else if(x==3){
      this.tempData.ObservedPlantTonHours==0
      this.obselecsts = false
      this.updatebut = true;
      this.currentbut = true;
      this.generated = false;
      this.downloadbut=false;
      this.editInput = true
      //alert(this.updatebut)
      //console.log("three points",this.obselecsts)
    }
    else{
      this.obselecsts = true
      this.obkWH=false
      this.obton=false
      this.obeff=false
    }
    //console.log("Hi working")
};

check_list(){
  var withoutsec_obj = {0: [0,"inputvalue1","BaseCaseCondenserDesignDeltaT"],
  2: [1,"inputvalue1","BaseCaseEvaporatorDesignDeltaT"],
  4: [13,"inputvalue1","PrimaryHead"],
  6: [14,"inputvalue1","PrimaryMaxFlow"],
  10: [2,"inputvalue1","BaseCaseNamePlateCapacity"],
  13: [9,"inputvalue","DaysPerWeek"],
  14: [5,"inputvalue1","DesignDayCoolingLoad"],
  17: [7,"inputvalue","HoursPerDay"],
  18: [8,"inputvalue","MonthPerYear"],
  20: [28,"inputvalue","PrimaryPumpNumber"],
  25: [3,"inputvalue1","BaseCaseChillerMinEcwt"],
  28: [4,"inputvalue1","BaseCaseDesigndayChwstSp"],
  30: [16,"inputvalue","BaseCaseNumberOfChillers"],
  31: [17,"inputvalue","BaseCaseNumberOfDutyChillers"],
  32: [16,"inputvalue1","CWHead"],
  33: [17,"inputvalue1","CWMaxFlow"],
  34: [32,"inputvalue","CWNumber"],
  35: [21,"inputvalue","HorsePowerPerFan"],
  36: [18,"inputvalue","NumberOfCoolingTowers"],
  37: [19,"inputvalue","TotalNumberOfFans"],
  42: [15,"inputvalue1","PrimaryMotorPowerOnNameplate"],
  54: [18,"inputvalue1","CondenserMotorPowerOnNameplate"]
  //60: [6,"inputvalue1","TowerResetTemperature"]
}
var withoutsec_arr = [0,2,4,6,10,13,14,17,18,20,25,28,30,31,32,33,34,35,36,37,42,54]

var withsec_obj = {0: [0,"inputvalue1","BaseCaseCondenserDesignDeltaT"],
  2: [1,"inputvalue1","BaseCaseEvaporatorDesignDeltaT"],
  4: [13,"inputvalue1","PrimaryHead"],
  6: [14,"inputvalue1","PrimaryMaxFlow"],
  10: [2,"inputvalue1","BaseCaseNamePlateCapacity"],
  13: [9,"inputvalue","DaysPerWeek"],
  14: [5,"inputvalue1","DesignDayCoolingLoad"],
  17: [7,"inputvalue","HoursPerDay"],
  18: [8,"inputvalue","MonthPerYear"],
  20: [27,"inputvalue","PrimaryPumpNumber"],
  25: [3,"inputvalue1","BaseCaseChillerMinEcwt"],
  28: [4,"inputvalue1","BaseCaseDesigndayChwstSp"],
  30: [15,"inputvalue","BaseCaseNumberOfChillers"],
  31: [16,"inputvalue","BaseCaseNumberOfDutyChillers"],
  32: [19,"inputvalue1","CWHead"],
  33: [20,"inputvalue1","CWMaxFlow"],
  34: [37,"inputvalue","CWNumber"],
  35: [21,"inputvalue","HorsePowerPerFan"],
  36: [17,"inputvalue","NumberOfCoolingTowers"],
  37: [18,"inputvalue","TotalNumberOfFans"],
  42: [15,"inputvalue1","PrimaryMotorPowerOnNameplate"],
  54: [21,"inputvalue1","CondenserMotorPowerOnNameplate"],
//  60: [6,"inputvalue1","TowerResetTemperature"],
  21: [16,"inputvalue1","SecondaryHead"],
  23: [17,"inputvalue1","SecondaryMaxFlow"],
  24: [32,"inputvalue","SecondaryNumber"],
  48: [18,"inputvalue1","SecondaryMotorPowerOnNameplate"]
}
var withsec_arr = [0,2,4,6,10,13,14,17,18,20,25,28,30,31,32,33,34,35,36,37,42,54,21,23,24,48]
//alert("hello")

var revisit_arr = []
var input_arr = {}

if( this.tempData["DistributionCircuitConfiguration"]== "ConstantPrimary" || this.tempData["DistributionCircuitConfiguration"]=="VariablePrimary"){
  revisit_arr = withoutsec_arr
  input_arr = withoutsec_obj
}

else{
  revisit_arr = withsec_arr
  input_arr = withsec_obj
}

  var alert_comment = [". Number of duty chillers should be less than or equal to number of chillers.",
  ". Design day cooling load can't be greater than total capacity of duty chillers.",
    ]
  var alert_add = []
  for (let index = 0; index < revisit_arr.length; index++) {
    if (this.tempData[input_arr[revisit_arr[index]][2]] == "0" || this.tempData[input_arr[revisit_arr[index]][2]] == "") {
      alert_add.push(". "+ input_arr[revisit_arr[index]][2] + " couldn't be zero.")
    var input_elemen = <HTMLElement> document.getElementsByClassName(input_arr[revisit_arr[index]][1])[input_arr[revisit_arr[index]][0]];
        input_elemen.style.border = "1px solid Red";
    var eact = <HTMLElement>  document.getElementsByClassName("inputvalue")[15]

    }
    else{
    var input_elemen = <HTMLElement> document.getElementsByClassName(input_arr[revisit_arr[index]][1])[input_arr[revisit_arr[index]][0]];
      input_elemen.style.border = "0px"
      input_elemen.style.borderLeft = "1px solid grey";
    }
  }

  // if(this.tempData["BaseCaseNumberOfChillers"]<=this.tempData["BaseCaseNumberOfDutyChillers"]){
  if(this.tempData["BaseCaseNumberOfChillers"]>=this.tempData["BaseCaseNumberOfDutyChillers"]){
    alert_comment.shift()
  }
  if((Number(this.tempData["BaseCaseNumberOfDutyChillers"])*Number(this.tempData["BaseCaseNamePlateCapacity"]))>=Number(this.tempData["DesignDayCoolingLoad"])){
    if(alert_comment.length>1){alert_comment.pop();}
    else{alert_comment.shift()}
  }
  alert_comment = alert_comment.concat(alert_add)
  return alert_comment
}

Adderrorow(alert_comment){
  var table: HTMLTableElement = <HTMLTableElement> document.getElementById("myTable");
  for (let ro = 0; ro < alert_comment.length; ro++) {
    var row1 = table.insertRow(ro);
    var cell1 = row1.insertCell(0);
    cell1.innerHTML = (ro+1) + alert_comment[ro];
  }

  var isMobileVersion = document.getElementsByClassName('modal-body');
if (isMobileVersion.length > 0) {
  const element = <HTMLElement> document.getElementsByClassName('modal-body')[0];
        element.style.height = (100+(20*alert_comment.length))+"px";
  }
else{

}
if(alert_comment.length>0){return "notfine";}
else{return "fine"}

  // function myFunction(x) {
  //   if (x.matches) { // If media query matches
  //     const element = <HTMLElement> document.getElementsByClassName('modal-dialog')[1];
  //     element.style.height = "1000px";
  //     alert("myway")
  //   } else {
  //    alert("otherway")
  //   }
  // }

  // var x = window.matchMedia("(max-width: 500px)")
  // myFunction(x) // Call listener function at run time
  // x.addListener(myFunction)

}

Help(content) {
  var alert_comment = this.check_list()
  if(alert_comment.length > 0){
    this.generated = false
    this.updatebut = true
    this.currentbut = true
    this.disablemodify = false,this.editInput = true
    // this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    // }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    // });
    var error_status =this.Adderrorow(alert_comment)
    return}
  else{this.GenerateRPT()}

}

Multiunitselec(){
  var obj_arr = {
  "Temp":	[1,3,26,29,61,74,76],
  "Head":	[5,22,68],
  "Flow":	[7,67,69],
  "Power":	[43,49,55],
  "Load":	[11,15]}

    var data_obj =this.tempData
    if(this.multiutobj["system"] == "imperial"){
      this.multiutobj["Flowunit"] = "US gpm",this.multiutobj["Loadunit"] = "Ton"
      this.multiutobj["Powunit"] = "HP",this.multiutobj["headunit"] = "ft",this.multiutobj["tempunit"] = "Fahrenheit"
    }
    else{
      this.multiutobj["Flowunit"] = "L/s",this.multiutobj["Loadunit"] = "kW"
      this.multiutobj["Powunit"] = "kW",this.multiutobj["headunit"] = "m",this.multiutobj["tempunit"] = "Celsius"
    }
    for (let index = 0; index < Object.keys(obj_arr).length; index++) {
      for (let index2 = 0; index2 < obj_arr[Object.keys(obj_arr)[index]].length; index2++) {
        var newarr = Object.values(obj_arr)[index]
        data_obj[Object.keys(data_obj)[newarr[index2]]] = Object.values(this.multiutobj)[index]
      }

    }
    alert("Unit Change Applied")
    this.tempData = data_obj

}


}
